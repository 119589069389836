<template>
  <div class="content">
    <div class="banner">
      <div
        class="infoBanner"
        :class="'info_banner' + (index + 1)"
        v-for="(item, index) in newbannerList"
        :key="index"
        @click="to(item)"
      >
        <div class="info_banner_box">
          <img :src="item.apparatusFileId" />
        </div>

        <div class="banner_text">
          <div class="mask"></div>
          <div class="textCntanier">
            <div class="title" v-if="index == 0">{{ item.apparatusType }}</div>
            <div class="text">
              <span v-if="index == 2">{{ item.apparatusType }}</span>
              {{ item.apparatusName }}
            </div>
            <div class="box" v-if="index != 2">
              <div class="box_text" v-for="tag in item.apparatusTag" :key="tag">
                {{ tag }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="center">
      <content-title
        :first="'众测产品'"
        :scende="'更多产品'"
        :to="'/check/product'"
      ></content-title>
      <!-- <flexLayout :row="4" :list="list" :type="'count'"></flexLayout> -->

      <release-layout
        :data="list"
        :row="4"
        :id="'layout2'"
        :type="'check'"
        :to="path1"
      ></release-layout>
    </div>
    <div class="bottom">
      <content-title
        :first="'众测报告'"
        :scende="'更多报告'"
        :to="'/check/report'"
      ></content-title>
      <release-layout
        :data="instrumentList"
        :row="3"
        :id="'layout1'"
        :to="path2"
        :type="'check1'"
      ></release-layout>
      <div class="button"></div>
    </div>
  </div>
</template>
<script>
import contentTitle from "../components/title.vue";
import releaseLayout from "../components/releaseLayout.vue";
// import flexLayout from "../components/flexLayout.vue";
import api from "../api/api";
import check from "../api/check";
export default {
  data() {
    return {
      instrumentList: [],
      list: [],
      bannerList: [],
      path1: {
        url: "/check/product/info",
      },
      path2: {
        url: "/check/report/info",
      },
    };
  },
  components: { contentTitle, releaseLayout },
  created() {
    this.banner();

    this.assessList(0);
    this.assessCommentList();
  },
  computed: {
    newbannerList() {
      return this.bannerList.slice(0, 3);
    },
  },
  methods: {
    banner() {
      let data = {
        type: "review",
      };
      api.indexTopAssess(data).then((res) => {
        this.bannerList = res.data.data.data;
      });
    },
    to(item) {
      this.$router.push(`/check/product/info/${item.id}`);
    },
    assessList(state) {
      let data = {
        state: state,
        page: 1,
      };
      check.assessList(data).then((res) => {
        let newList = [];
        let list = res.data.data.data;
        list.forEach((item) => {
          let data = {
            id: item.id,
            // username: item.author,
            // time: item.createTime,
            name: item.apparatusName,
            url: item.apparatusFileId,
            newsTitle: item.newsName,
            order: item.order,
            quantify: item.quantify,
            applyNum: item.applyNum,
            condition: item.condition,
            // viewCount: item.newsViews,
            // commentCount: item.commentNum,
            // likeCount: item.total,
          };
          newList.push(data);
        });
        this.list = newList;
      });
    },
    assessCommentList() {
      let data = {
        assessId: "",
        page: 1,
      };
      check.assessCommentList().then((res) => {
        let newList = [];
        let list = res.data.data.list;
        list.forEach((item) => {
          let data = {
            id: item.commentId,
            code: item.commentCode,
            catId: item.assessId,
            author: item.memberName,
            userIcon: item.memberIcon,
            url: item.apparatusFileId,
            time: item.createTime,
            text: item.description,
            userIcon: item.memberIcon,
            newsTitle: item.apparatusName,
            viewCount: item.hots,
            commentCount: item.opinionNum,
            likeCount: item.commentPros,
          };
          newList.push(data);
        });
        this.instrumentList = newList;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  width: 1243px;
  margin: 0 auto;
  margin-top: 170px;
  .banner {
    display: grid;
    grid-template-columns: 841px auto;
    grid-template-rows: auto auto;
    grid-column-gap: 25px;
    grid-row-gap: 25px;
    .infoBanner {
      font-size: 0;
    }
    .info_banner_box {
      border-radius: 7px;
      overflow: hidden;
      display: inline-block;
      width: 100%;
      position: relative;
    }
  }
  .info_banner1 {
    // height: 533px;
    position: relative;
    grid-row-start: 1;
    grid-row-end: 3;
    overflow: hidden;
    border-radius: 7px;
    font-size: 0;
    img {
      width: 100%;
      // height: 100%;
      border-radius: 7px;
      transition: all 0.5s;
    }
    .banner_text {
      top: 0;
      width: 245px;
      height: 100%;
      position: absolute;
      left: 0;
      .mask {
        width: 100%;
        height: 100%;
        background: #384148;
        opacity: 0.5;
      }
      .textCntanier {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        text-align: center;
        .title {
          font-size: 35px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #ffffff;
        }
        .text {
          font-size: 32px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #ffffff;
          width: 68px;
          text-align: left;
          margin: 0 auto;
        }
        .box {
          width: 133px;
          padding: 12px 27px;
          border: 1px solid #ffffff;
          margin: 0 auto;
          font-size: 27px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
    &:hover {
      img {
        transform: scale(1.05);
      }
    }
  }
  .info_banner2 {
    position: relative;
    overflow: hidden;
    border-radius: 7px;
    font-size: 0;
    img {
      width: 100%;
      // height: 100%;
      border-radius: 7px;
      transition: all 0.5s;
    }
    .banner_text {
      padding: 27px 12px 36px 12px;
      background: rgba(104, 119, 131, 0.47999998927116394);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      .text {
        font-size: 21px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #ffffff;
      }
      .box {
        padding: 7px 19px 12px 19px;
        border: 1px solid #ffffff;
        margin-top: 28px;
        text-align: center;
        .box_text {
          font-size: 21px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
    &:hover {
      img {
        transform: scale(1.05);
      }
    }
  }
  .info_banner3 {
    position: relative;
    align-self: end;
    overflow: hidden;
    border-radius: 7px;
    font-size: 0;
    img {
      width: 100%;
      // height: 100%;
      border-radius: 7px;
      transition: all 0.5s;
    }
    .banner_text {
      position: absolute;
      top: 50%;
      width: 100%;
      transform: translateY(-50%);
      background: linear-gradient(
        273deg,
        #6c2c2c 0%,
        rgba(92, 45, 45, 0.8399999737739563) 51%,
        rgba(196, 113, 113, 0.49000000953674316) 100%
      );
      .text {
        font-size: 27px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
      }
    }
    &:hover {
      img {
        transform: scale(1.05);
      }
    }
  }
  .center {
    margin-top: 33px;
  }
  .bottom {
    .button {
      margin-top: 65px;
      margin-bottom: 207px;
      text-align: center;
    }
    button {
      padding: 17px 64px;
      outline: none;
      background: #0394ff;
      box-shadow: 0px 5px 24px 1px
        rgba(2.9999998211860657, 147.9999303817749, 255, 0.5099999904632568);
      border: 1px solid #0394ff;
      color: #ffffff;
      border-radius: 65px;
      font-size: 21px;
      font-size: 21px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
    }
  }
}
</style>